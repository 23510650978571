$colour-plain: #fff;
$colour-primary: #d4002a;
$colour-secondary: #222327;
$colour-accent: #f1f1f1;
$colour-accent-secondary: #ccc;
$colour-accent-dark: #121212;
$colour-success: #37a800;
$colour-success-light: #eaf6e7;
$colour-error: #d4022a;
$colour-plain-transparent: transparentize($colour-plain, 0.6);
$colour-primary-dark: darken($colour-primary, 10%);
$colour-primary-light: lighten($colour-primary, 4%);
$colour-accent-transparent: transparentize($colour-accent, 0.4);
$colour-accent-secondary-dark: darken($colour-accent-secondary, 10%);
$colour-accent-dark-transparent-semi: transparentize($colour-accent-dark, 0.2);
$colour-accent-dark-transparent: transparentize($colour-accent-dark, 0.6);
$colour-accent-dark-transparent-almost: transparentize($colour-accent-dark, 0.91);
$colour-accent-dark-transparent-full: transparentize($colour-accent-dark, 1);
$heading-one-transform: uppercase;
$heading-transform: none;
$cta-primary-capitalisation: uppercase;
$cta-tertiary-capitalisation: uppercase;
$border-radius: 10px;
$cta-border-radius: 30px;
$typefaces: 'brand';

$boulder-grey: #767676;
$dove-grey: #6e6e6e;
$sunglow-yellow: #fec62c;
$venetian-red: #690218;

@import 'shared/mixins/font-face';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;600;800&display=swap&subset=arabic');
@include font-face('icons', 'avis-icons', 'avis-icons');
@import 'brand';

/* Custom Brand Styles */

table {

	tr:first-child {

		th:first-child[scope=row] {
			border-right-width: 0;

			@include rtl() {
				border-left-width: 0;
			}
		}
	}

	tr:nth-child(even) th {
		background: transparent;

		.l-row--variant-a &,
		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			background: transparent;
		}
	}

	tr:nth-child(even) td {

		.l-row--variant-c & {
			background: $colour-secondary;
		}

		.l-row--variant-d & {
			background: $colour-accent-dark;
		}
	}

	th {
		color: $colour-primary;

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			color: $colour-plain;
		}

		&[scope=row] {
			border-right: 2px solid $colour-primary;

			@include rtl() {
				border-left: 2px solid $colour-primary;
				border-right: 0;
			}

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
			}
		}

		&[scope=col] {
			border-bottom: 2px solid $colour-primary;

			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
				color: $colour-plain;
			}
		}
	}

	thead + tbody {

		tr:first-child {

			th:first-child[scope=row] {
				border-right-width: 2px;

				@include rtl() {
					border-left-width: 2px;
				}
			}
		}
	}

	.table--strip-inverse & {

		th:nth-child(even) {
			background: transparent;

			.l-row--variant-a &,
			.l-row--variant-b &,
			.l-row--variant-c &,
			.l-row--variant-d & {
				background: transparent;
			}
		}

		td:nth-child(even) {

			.l-row--variant-c & {
				background: $colour-secondary;
			}

			.l-row--variant-d & {
				background: $colour-accent-dark;
			}
		}
	}
}

ul {

	li:before {
		content: '\02666';
	}
}

.accordion {

	&__item {
		border-radius: 0;

		&__heading {

			&:before {
				border-left: 1px solid $colour-accent-secondary;
				padding-left: $spacing-unit-base;

				@include rtl() {
					border-left: 0;
					border-right: 1px solid $colour-accent-secondary;
					padding-left: 0;
					padding-right: $spacing-unit-base;
				}
			}
		}
	}
}

.alert {
	background: transparent;
	border: 1px solid $colour-accent-dark;
	color: $colour-accent-dark;

	.l-row--variant-a & {
		background: transparent;
	}

	.l-row--variant-b & {
		background: transparent;
		border-color: $colour-primary-dark;
	}

	.l-row--variant-c & {
		background: transparent;
		border-color: $colour-plain;
	}

	.l-row--variant-d & {
		background: transparent;
		border-color: $colour-plain;
	}

	&--error {
		border-color: $colour-error;

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			border-color: $colour-error;
		}
	}

	&--info {
		border-color: $sunglow-yellow;

		.icon {
			color: $sunglow-yellow;
		}

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			border-color: $sunglow-yellow;
		}
	}

	&--success {
		border-color: $colour-success;

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			border-color: $colour-success;
		}
	}
}

.booking-widget {

	&__results {
		border-radius: 0;
		margin: -1px 0 0;
	}

	&__station-details-toggle {
		text-transform: uppercase;
	}

	&__opening-times {
		background: $colour-accent;
		border-color: $colour-accent-dark;
		border-radius: 0;

		@include rtl() {
			border-radius: 0;
		}

		@include breakpoint($breakpoint-small) {

			&__heading {
				text-transform: uppercase;

				&:before {
					border-left: 1px solid $colour-accent-secondary;

					@include rtl() {
						border-left: 0;
						border-right: 0;
					}
				}
			}
		}
	}

	&__date-picker-container {

		.standard-form__input--active,
		.standard-form__input--active:focus {
			// scss-lint:disable PropertySortOrder
			// Need to make sure that the correct border colours display
			border-color: $colour-accent-dark;
			border-bottom-color: $colour-plain;
			// scss-lint:enable PropertySortOrder

			@include breakpoint($breakpoint-small) {
				border-bottom-color: $colour-accent-dark;
			}
		}

		&--open {

			&:after {
				background: $colour-plain;
				content: '';
				display: block;
				height: 1px;
				left: 1px;
				position: absolute;
				right: 1px;
				top: 100%;
				z-index: 101;

				@include breakpoint($breakpoint-small) {
					display: none;
				}
			}
		}
	}

	&__time-picker-container {
		border-radius: 0;
	}
}

.cta {

	.l-row--variant-b &,
	.l-row--variant-c &,
	.l-row--variant-d & {

		&:hover {
			background-color: $colour-plain;
		}
	}

	&--secondary {
		border-color: $colour-primary;
	}

	&--tertiary {

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {

			&:hover {
				background-color: transparent;
			}
		}
	}
}

.date-picker {
	background: $colour-accent;
	border-color: $colour-accent-dark;
	border-radius: 0;
	margin-top: 0;

	&:before {
		background: $colour-plain;
		content: '';
		display: block;
		height: 64px;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;

		@include breakpoint($breakpoint-small) {
			display: none;
		}
	}

	.pika-title {
		margin-bottom: $spacing-unit-small;
		text-transform: uppercase;
	}

	.is-selected {

		.pika-button {
			border-radius: 0;
		}
	}
}

.extra {

	&__price--minor {
		color: $boulder-grey;

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			color: $colour-plain;
		}
	}
}

.feature {

	&__caption {
		color: $boulder-grey;

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			color: $colour-plain;
		}
	}
}

.language-country-selector__heading {
	text-transform: uppercase;
}

.link-list {

	&__item {
		border-top: 1px solid $colour-accent-secondary;

		.l-row--variant-b & {
			border-top-color: $colour-primary-dark;
		}

		.l-row--variant-c &,
		.l-row--variant-d & {
			border-top-color: $colour-plain;
		}
	}

	&__link {
		margin-bottom: 0;
		padding: $spacing-unit-small-mid 0;
	}
}

.map {

	&__info {
		box-shadow: 0 0 15px transparentize($colour-accent-dark, 0.7);
	}
}

.option-list {

	&__control {

		&:after {
			border-radius: 0;
			box-shadow: inset 0 5px 0 darken($colour-plain, 3%);
		}

		&--radio {

			&:after {
				border-radius: 50%;
			}
		}
	}
}

.option-promo {

	&__header {
		position: relative;

		&:after {
			background: url('../img/diagonal-lines.png');
			background-size: cover;
			bottom: 0;
			content: '';
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			width: 167px;
			z-index: 1;
		}
	}

	&__heading {
		font-family: $font-family-brand;
		font-weight: $font-weight-brand;
		text-transform: $heading-one-transform;

		@include rtl() {
			font-family: $font-family-brand-arabic;
			font-weight: $font-weight-brand-arabic;
		}
	}
}

.primary-navigation {
	background: $colour-primary;

	@include breakpoint($breakpoint-small) {
		background: transparent;
	}

	&__list {

		@include breakpoint($breakpoint-small) {
			padding-top: 0;
		}

		&:before {
			background: $colour-primary;
		}
	}

	&__item {

		@include breakpoint($breakpoint-small) {
			border-bottom: 1px solid $colour-accent-secondary;

			&--has-mega-menu {

				.primary-navigation__link:after {
					line-height: 52px;
				}
			}
		}
	}

	&__link {
		color: $colour-plain;
		text-transform: uppercase;

		@include breakpoint($breakpoint-small) {
			color: $colour-accent-dark;
			margin-bottom: 0;
			padding-bottom: $spacing-unit-small-mid;
			padding-top: $spacing-unit-small-mid;
		}

		&:hover {
			background-color: $colour-plain;
			color: $colour-primary;
		}
	}

	&__mega-menu__heading {

		@include breakpoint($breakpoint-small) {
			border: 1px solid $colour-primary;
			border-width: 1px 0;
			margin: -1px 0 0;
			padding: $spacing-unit-small-mid 0;
			text-transform: uppercase;
		}
	}

	&__mega-menu__item {
		border-top: 1px solid $colour-accent-secondary;

		@include breakpoint($breakpoint-small) {
			border-bottom: 1px solid $colour-accent-secondary;
			border-top: 0;

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	&__mega-menu__link {
		margin-bottom: 0;
		padding: $spacing-unit-small-mid 0;
		text-transform: uppercase;
	}
}

.promo {

	&__details {
		background: $colour-accent-secondary;

		.l-row--variant-a & {
			background: $colour-accent-secondary;
		}

		.l-row--variant-d & {
			background: $colour-accent-dark;
			color: $colour-plain;
		}
	}
}

.reference-list {

	&__note {
		color: $boulder-grey;

		.l-row--variant-b &,
		.l-row--variant-c &,
		.l-row--variant-d & {
			color: $colour-plain;
		}
	}
}

.search-result {

	mark {
		background: $sunglow-yellow;
		padding: 3px;
	}
}

.standard-form {

	&__input,
	&__textarea,
	&__file-upload__file-name,
	&__select {
		border-radius: 0;
		box-shadow: inset 0 5px 0 darken($colour-plain, 3%);

		&[disabled] {
			box-shadow: none;
		}

		&:focus,
		&--active {
			border-color: $boulder-grey;
		}

		&--error {
			border-color: $colour-primary;

			&:focus {
				border-color: $colour-primary-dark;
			}
		}
	}

	&__select {
		border-radius: 0;
		box-shadow: inset 0 5px 0 darken($colour-plain, 3%);

		&--error {
			border-color: $colour-primary;
		}

		&:before {
			border-left: 1px solid $colour-accent-secondary;

			@include rtl() {
				border-left: 0;
				border-right: 1px solid $colour-accent-secondary;
			}
		}
	}

	&__datepicker {
		background: $colour-accent;
		border-color: $colour-accent-dark;
		border-radius: 0;

		.pika-label {
			background: $colour-accent;
		}

		.is-selected {

			.pika-button {
				border-radius: 0;
			}
		}
	}

	&__submit {
		border: 2px solid $colour-primary;

		.l-row--variant-b & {
			border-color: $colour-accent-dark;
		}

		&:hover {
			border: 2px solid $colour-accent-dark;
		}

		&[disabled] {
			border-color: $colour-accent-secondary;
		}

		&--tertiary {
			border: 0;

			&:hover {
				border: 0;
			}
		}
	}

	&__file-upload label[disabled] {

		.standard-form__file-upload__file-name {
			box-shadow: none;
		}
	}
}

.step-tracker {

	&__step-prompt--inactive {
		color: $dove-grey;
	}

	&__promo-message {
		border-radius: 0;
		margin-left: -50px;
		margin-right: $spacing-unit-base * -1;
		padding-left: 50px;

		@include breakpoint($breakpoint-small) {
			margin-bottom: $spacing-unit-base * -1;
		}
	}

	&--summary {

		.step-tracker__promo-message {
			border-radius: 0;
			margin-left: $spacing-unit-base * -1;
			margin-right: $spacing-unit-base * -1;
			padding-left: $spacing-unit-base;

			@include rtl() {
				margin-right: $spacing-unit-base * -1;
			}

			@include breakpoint($breakpoint-small) {
				margin-bottom: $spacing-unit-base * -1;
			}
		}
	}
}

.supplementary-navigation {

	&--minor {
		border-right: 1px solid transparentize($colour-plain, 0.57);
	}

	&__list {

		@include breakpoint($breakpoint-small) {
			border-right: 1px solid transparentize($colour-plain, 0.57);

			&--no-border {
				border-right: 0;
			}
		}
	}

	&__item {
		border-right: 1px solid transparentize($colour-plain, 0.57);

		@include rtl() {
			border-left: 0;
			border-right: 1px solid transparentize($colour-plain, 0.57);
		}

		&:last-child {
			border-right-width: 0;

			@include rtl() {
				border-left-width: 0;
			}
		}

		&--desktop-last {
			border-right-width: 0;

			@include rtl() {
				border-left-width: 0;
			}

			@include breakpoint($breakpoint-small) {
				border-right-width: 1px;

				@include rtl() {
					border-left-width: 1px;
					border-right-width: 0;
				}
			}
		}

		&--active {

			.supplementary-navigation__link {
				background: $colour-plain;
				color: $colour-primary;
			}
		}
	}

	&__link {
		background: $colour-primary;
		color: $colour-plain;
		text-transform: uppercase;

		&:hover {
			background: $colour-plain;
			color: $colour-primary;
		}

		&__inner {
			text-transform: uppercase;
		}
	}

	&__flyout {

		.search .icon {
			color: $boulder-grey;
		}
	}
}

.time-picker {
	background: $colour-accent;
	border-color: $colour-accent-dark;
	border-radius: 0;
	top: 44px;
}

.time-picker-input--active,
.time-picker-input--active:focus {
	border-bottom: transparent;
	border-color: $colour-accent-dark;
}

.tooltip {
	border-radius: 50%;
}

.search__input:focus {
	border-color: $colour-accent-secondary;
}

.typeahead__list {
	border-color: $colour-accent-secondary;
	border-radius: 0;
}

.vehicle {

	&__accordion {

		.accordion__item {

			&__heading {

				&:before {

					.l-row--variant-b & {
						border-color: $colour-primary-dark;
					}
				}
			}
		}
	}

	&__promo-message {
		border-radius: 0;
		margin-left: $spacing-unit-base * -1;
		margin-right: $spacing-unit-base * -1;
	}

	&__prices-heading {
		text-transform: uppercase;
	}

	&__prices-caption {
		text-transform: none;
	}

	&--prestige {

		.vehicle__category {
			color: $colour-plain;

			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}

			&:before {
				background: $venetian-red;

				.l-row--variant-a &,
				.l-row--variant-b & {
					background: $venetian-red;
				}
			}
		}

		.vehicle__toggle {
			border-color: $colour-plain;
			color: $colour-plain;

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
				color: $colour-plain;
			}
		}

		.vehicle__note strong {
			color: $colour-accent-dark;
		}

		.l-col--12:not(.vehicle-matrix) > & {

			.vehicle__overview {

				&:before {
					background: $venetian-red;

					.l-row--variant-a &,
					.l-row--variant-b & {
						background: $venetian-red;
					}
				}
			}
		}
	}

	&--select {

		.vehicle__category {
			color: $colour-plain;

			.l-row--variant-c &,
			.l-row--variant-d & {
				color: $colour-plain;
			}

			&:before {
				background: $boulder-grey;

				.l-row--variant-a &,
				.l-row--variant-b & {
					background: $boulder-grey;
				}
			}
		}

		.vehicle__toggle {
			border-color: $colour-plain;
			color: $colour-plain;

			.l-row--variant-c &,
			.l-row--variant-d & {
				border-color: $colour-plain;
				color: $colour-plain;
			}
		}

		.vehicle__note strong {
			color: $colour-accent-dark;
		}

		.l-col--12:not(.vehicle-matrix) > & {

			.vehicle__overview {

				&:before {
					background: $boulder-grey;

					.l-row--variant-a &,
					.l-row--variant-b & {
						background: $boulder-grey;
					}
				}
			}
		}
	}

	&--eco {

		.vehicle__note strong {
			color: $colour-accent-dark;
		}
	}
}

.header {

	&__row {
		background: $colour-accent;

		&--main {
			background: $colour-primary;

			&:before {
				background: $colour-primary;
			}
		}

		&--secondary {
			background: darken($colour-primary, 7%);
			color: $colour-plain;

			.sign-in-bar__form {

				.standard-form__input {

					&--error {
						border-color: $venetian-red;
					}
				}

				.standard-form__submit {
					background: $colour-accent-dark;
					border-color: $colour-accent-dark;

					&:hover {
						background: $colour-plain;
						border-color: $colour-plain;
						color: $colour-accent-dark;
					}

					&--secondary {
						background: transparent;
						border-color: $colour-plain;
						color: $colour-plain;
					}

					&--tertiary {
						background: transparent;
						color: $colour-plain;

						&:hover {
							background: transparent;
							color: $colour-plain;
						}
					}
				}

				.error {
					color: $colour-plain;
				}
			}

			.sign-in-bar__welcome {

				a {
					border-color: $colour-plain;
					color: $colour-plain;
				}
			}

			.sign-in-bar__detail {
				border-right-color: $venetian-red;

				@include rtl() {
					border-left-color: $venetian-red;
				}
			}

			.sign-in-bar__progress-bar__bar {
				background: $venetian-red;

				&::-webkit-progress-bar {
					background: $venetian-red;
				}
			}

			.sign-in-bar__action {

				.cta.cta--tertiary {
					color: $colour-plain;

					&:before {
						color: $colour-plain;
					}
				}
			}
		}
	}

	.primary-navigation__toggle {
		color: $colour-plain;

		&--active {
			background: $colour-plain;
			color: $colour-primary;
		}
	}
}
